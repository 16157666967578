<template>
  <div class="row mt-2">
    <!-- row information and select row -->
    <div class="col-md-5 col-sm-12">
      <div class="d-flex align-items-center float-left ml-2">
        <div>Showing {{ page }} to</div>
        <div class="ml-1 mr-1">
          <b-form-select
            v-model="limit"
            :options="page_options"
            class="border-secondary"
          />
        </div>
        <div>
          of
          {{ data.total }} Entries
        </div>
      </div>
    </div>

    <!-- pagination -->
    <div class="col-md-7 col-sm-12">
      <div class="float-right mr-2">
        <b-pagination
          v-model="page"
          :total-rows="data.total"
          :per-page="data.limit"
          size="md"
          class="pagination-info"
        />
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery"
import { BFormSelect, BPagination } from "bootstrap-vue";

export default {
  name: "pagination",
  components: {
    BFormSelect,
    BPagination,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      limit: this.data.limit,
      page: this.data.page,
      page_options: [5, 10, 25, 50, 100],
    };
  },
  watch: {
    page() {
      this.updateQuery("page", this.page);
    },
    limit() {
      this.updateQuery("items", this.limit);
    },
  },
  methods: {
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if($.param(this.$route.query) != $.param(queries)){
        this.$router.replace({ query: queries });
      }
    },
  },
};
</script>