<template>
  <div>
    <b-table
      hover
      responsive
      :fields="data.fields"
      :items="data.items"
      :filter="data.filter"
      sticky-header="65vh"
      show-empty
      @row-clicked="onRowClicked"
    >
      <!-- show empty data -->
      <template #empty>
        <div class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Silahkan Lakukan Pencarian dengan Parameter Lain
            </p>
          </center>
        </div>
      </template>

      <!-- show empty filtered -->
      <template #emptyfiltered>
        <div class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Silahkan Lakukan Pencarian dengan Parameter Lain
            </p>
          </center>
        </div>
      </template>

      <!-- custom cell no -->
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <!-- custom cell fixed -->
      <template #cell(fixed)="data">
        <b-button variant="success" size="sm" @click="fixedData(data.item._id)">
          Fixed
        </b-button>
      </template>

      <!-- custom cell title -->
      <template #cell(title)="data">
        <p style="width: 20rem !important">{{ data.value }}</p>
      </template>

      <!-- custom cell url -->
      <template #cell(url)="data">
        <a
          :href="data.value"
          target="_blank"
          class="btn btn-sm btn-outline-info"
          >Kunjungi</a
        >
      </template>

      <!-- custom cell cakupan -->
      <template #cell(cakupan)="data">
        <div>
          {{ textCapitalize(data.value) }}
        </div>
      </template>

      <!-- custom cell scraper name -->
      <template #cell(scraper_name)="data">
        <div v-if="data.value" class="d-flex align-items-center">
          {{ data.value }}
          <b-button
            @click="copyToClipboard(data.value)"
            variant="flat"
            class="btn-icon btn-link ml-2"
            size="10"
            v-b-tooltip.hover.v-dark="'Salin'"
          >
            <feather-icon
              :icon="
                is_copied && text_copied == data.value
                  ? 'CheckSquareIcon'
                  : 'CopyIcon'
              "
              size="18"
            />
          </b-button>
        </div>
        <div v-else>
          Tidak Tersedia!
        </div>
      </template>

      <!-- custom cell scraper status -->
      <template #cell(scraper_status)="data">
        <div v-if="data.value">
          <b-badge
            :variant="
              data.value.toLowerCase() == 'aktif' ? 'success' : 'danger'
            "
          >
            {{ data.value }}
          </b-badge>
        </div>
        <div v-else-if="data.item.status == 1 || data.item.status == 0">
          <b-badge :variant="data.item.status == 1 ? 'success' : 'danger'">
            {{ data.item.status == 1 ? "Aktif" : "Nonaktif" }}
          </b-badge>
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell scraper name -->
      <template #cell(_id)="data">
        <div v-if="data.value" class="d-flex align-items-center">
          {{ data.value }}
          <b-button
            @click="copyToClipboard(data.value)"
            variant="flat"
            class="btn-icon btn-link ml-2"
            size="10"
            v-b-tooltip.hover.v-dark="'Salin'"
          >
            <feather-icon
              :icon="
                is_copied && text_copied == data.value
                  ? 'CheckSquareIcon'
                  : 'CopyIcon'
              "
              size="18"
            />
          </b-button>
        </div>
      </template>

      <!-- custom cell file_name -->
      <template #cell(file_name)="data">
        <div v-if="data.value" class="d-flex align-items-center">
          {{ data.value }}
          <b-button
            @click="copyToClipboard(data.value)"
            variant="flat"
            class="btn-icon btn-link ml-2"
            size="10"
            v-b-tooltip.hover.v-dark="'Salin'"
          >
            <feather-icon
              :icon="
                is_copied && text_copied == data.value
                  ? 'CheckSquareIcon'
                  : 'CopyIcon'
              "
              size="18"
            />
          </b-button>
        </div>
      </template>

      <!-- custom cell timestamp -->
      <template #cell(timestamp)="data">
        <b-badge variant="light-success">{{ parseDate(data.value) }}</b-badge>
      </template>

      <!-- custom cell start -->
      <template #cell(start)="data">
        <b-badge variant="light-success">{{ parseDate(data.value) }}</b-badge>
      </template>

      <!-- custom cell start_date -->
      <template #cell(start_date)="data">
        <b-badge variant="light-success">{{ parseDate(data.value) }}</b-badge>
      </template>

      <!-- custom cell end -->
      <template #cell(end)="data">
        <b-badge variant="light-danger">{{
          data.value ? parseDate(data.value) : "Still Running"
        }}</b-badge>
      </template>

      <!-- custom cell end_date -->
      <template #cell(end_date)="data">
        <b-badge variant="light-danger">{{
          data.value ? parseDate(data.value) : "Still Running"
        }}</b-badge>
      </template>

      <!-- custom cell date -->
      <template #cell(date)="data">
        <b-badge variant="light-success">{{
          data.value && data.value != "*" ? parseDate(data.value) : data.value
        }}</b-badge>
      </template>

      <!-- custom cell created_at -->
      <template #cell(created_at)="data">
        <b-badge variant="light-success">{{
          data.value && data.value != "*" ? parseDate(data.value) : data.value
        }}</b-badge>
      </template>

      <!-- custom cell hour -->
      <template #cell(hour)="data">
        {{ data.value && data.value != "*" ? data.value.join(", ") : "-" }}
      </template>

      <!-- custom cell status_error -->
      <template #cell(status_error)="data">
        <b-button
          variant="danger"
          size="sm"
          style="width: 8rem !important"
          @click="onProgress(data.item._id)"
        >
          <span class="align-midle">Error</span>
        </b-button>
      </template>

      <!-- custom cell status_missingclass -->
      <template #cell(status_missingclass)="data">
        <b-button
          variant="warning"
          size="sm"
          style="width: 9rem !important"
          @click="onProgress(data.item._id)"
        >
          <span class="align-midle">Missing Class</span>
        </b-button>
      </template>

      <!-- custom cell status_redirected -->
      <template #cell(status_redirected)="data">
        <b-button
          variant="info"
          size="sm"
          style="width: 8rem !important"
          @click="onProgress(data.item._id)"
        >
          <span class="align-midle">Redirected</span>
        </b-button>
      </template>

      <!-- custom cell status_unavailable -->
      <template #cell(status_unavailable)="data">
        <b-button
          variant="secondary"
          size="sm"
          style="width: 8rem !important"
          @click="onProgress(data.item._id)"
        >
          <span class="align-midle">Unavailable</span>
        </b-button>
      </template>

      <!-- custom cell status_on_progress -->
      <template #cell(status_on_progress)="data">
        <b-button
          variant="secondary"
          size="sm"
          style="width: 8rem !important"
          @click="fixedData(data.item._id)"
        >
          <span class="align-midle">On Progress</span>
        </b-button>
      </template>

      <!-- custom cell status_fixed -->
      <template #cell(status_fixed)>
        <b-button variant="success" style="width: 8rem !important" size="sm">
          <span class="align-midle">Fixed</span>
        </b-button>
      </template>

      <!-- custom cell status -->
      <template #cell(status)="data">
        <b-badge
          v-if="data.value.toLowerCase() == 'error'"
          pill
          variant="danger"
          style="width: 8rem !important"
        >
          Error
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'redirected'"
          pill
          variant="info"
          style="width: 8rem !important"
        >
          Redirected
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'unavailable'"
          pill
          variant="secondary"
          style="width: 8rem !important"
        >
          Unavailable
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'missingclass'"
          pill
          variant="warning"
          style="width: 8rem !important"
        >
          Missing Class
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'onprogress'"
          pill
          variant="light-warning"
          style="width: 8rem !important"
        >
          On Progress
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'running'"
          pill
          variant="primary"
          style="width: 8rem !important"
        >
          Running
        </b-badge>
        <b-badge
          v-else-if="data.value.toLowerCase() == 'fixed'"
          pill
          variant="success"
          style="width: 8rem !important"
        >
          Fixed
        </b-badge>
        <b-badge v-else pill variant="secondary" style="width: 8rem !important">
          Open
        </b-badge>
      </template>

      <!-- custom cell error_message -->
      <template #cell(error_message)="data">
        <div v-if="data.value">
          <div
            v-if="Array.isArray(data.value)"
            class="scroller"
            style="
            max-height: 10rem;
            width: max-content;
            max-width: 35rem;
            justify-content: center !important;
          "
          >
            <div
              v-for="(item, index) in data.value"
              :key="index"
              class="border rounded mb-1 p-1"
            >
              <div class="row">
                <div class="col-md-2 d-flex pr-0">
                  URL <span class="ml-auto">:</span>
                </div>
                <div class="col-md-10">{{ item.url }}</div>
                <div class="col-md-2 d-flex pr-0">
                  Error <span class="ml-auto">:</span>
                </div>
                <div class="col-md-10">{{ item.error }}</div>
                <div class="col-md-2 d-flex pr-0">
                  Type <span class="ml-auto">:</span>
                </div>
                <div class="col-md-10">{{ item.type }}</div>
                <div class="col-md-2 d-flex pr-0">
                  Line <span class="ml-auto">:</span>
                </div>
                <div class="col-md-10">{{ item.line }}</div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="scroller"
            style="
            max-height: 10rem;
            width: max-content;
            max-width: 35rem;
            justify-content: center !important;
          "
          >
            {{
              data.value
                ? data.value.length > 200
                  ? data.value.substring(0, 200) + "..."
                  : data.value
                : "-"
            }}
          </div>
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell message -->
      <template #cell(message)="data">
        <div v-if="data.value" class="scroller" style="max-height: 10rem">
          {{
            data.value.length > 200
              ? data.value.substring(0, 200) + "..."
              : data.value
          }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell solution -->
      <template #cell(solution)="data">
        <div v-if="data.value" class="scroller" style="max-height: 10rem">
          {{
            data.value.length > 200
              ? data.value.substring(0, 200) + "..."
              : data.value
          }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell tier -->
      <template #cell(tier)="data">
        <div class=" text-nowrap">
          {{ data.value ? `Tier ${data.value}` : "Untier" }}
        </div>
      </template>

      <!-- custom cell updated -->
      <template #cell(updated)="data">
        <div style="width: max-content !important">
          <div v-if="data.item.username">
            oleh "<strong> {{ data.item.username }} </strong>"
          </div>
          <br />
          {{
            parseDate(data.item.updated_at || data.item.updated_at_keterangan)
          }}
        </div>
      </template>

      <!-- custom cell description -->
      <template #cell(description)="data">
        <div v-if="data.value" class="scroller" style="max-height: 10rem;">
          <div
            v-if="data.item.description_updated_at"
            class="text-primary text-nowrap"
          >
            <span v-if="data.item.updated_by">
              {{ data.item.updated_by }}:
            </span>
            [{{ parseDate(data.item.description_updated_at) }}]
          </div>
          {{ data.value }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell last_fixing -->
      <template #cell(last_fixing)="data">
        <b-badge v-if="data.value" variant="light-success">
          {{ data.value ? parseDate(data.value) : "-" }}
        </b-badge>
        <div v-else>
          -
        </div>
      </template>

      <!-- custom cell last_streams_inserted -->
      <template #cell(last_streams_inserted)="data">
        <b-badge v-if="data.value" variant="light-success">
          {{ data.value ? parseDate(data.value) : "-" }}
        </b-badge>
        <div v-else>
          -
        </div>
      </template>

      <!-- custom cell problem_classification -->
      <template #cell(problem_classification)="data">
        <div v-if="data.value">
          {{ data.value }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell log_tier_description -->
      <template #cell(log_tier_description)="data">
        <div style="width: 20rem;">
          <b-form-textarea
            v-model="data.item.description"
            placeholder="Masukkan deskripsi..."
          />
          <div class="d-flex justify-content-between align-items-center">
            <b-badge v-if="data.item.updated_at" variant="warning">
              {{ parseDate(data.item.updated_at) }}
            </b-badge>
            <b-button
              size="sm"
              variant="outline-success"
              class="ml-auto"
              style="margin-top: 5px;"
              :disabled="
                !data.item.description || !data.item.description.trim()
              "
              @click="viewDetail(data.item._id)"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </template>

      <!-- custom cell error_msg -->
      <template #cell(error_msg)="data">
        <div
          v-if="data.value"
          class="scroller"
          style="
            max-height: 10rem;
            width: max-content;
            max-width: 35rem;
            justify-content: center !important;
          "
        >
          {{
            data.value
              ? data.value.length > 200
                ? data.value.substring(0, 200) + "..."
                : data.value
              : "-"
          }}
        </div>
        <div v-else class="text-center">
          -
        </div>
      </template>

      <!-- custom cell media_name -->
      <template #cell(media_name)="data">
        <div class="scroller" style="max-height: 10rem;">
          <div
            v-if="Array.isArray(data.value)"
            class="d-flex flex-wrap"
            style="gap:5px"
          >
            <b-badge v-for="item in data.value" variant="light-primary">
              {{ item }}
            </b-badge>
          </div>
          <div v-else>
            {{ data.value }}
          </div>
        </div>
      </template>

      <!-- custom cell grafana_url -->
      <template #cell(grafana_url)="data">
        <a :href="data.value" target="_blank">
          {{ data.value }}
        </a>
      </template>

      <!-- custom cell role -->
      <template #cell(role)="data">
        <span class="badge badge-success" style="width: 5rem">
          {{ data.value == 1 ? "Admin" : "User" }}
        </span>
      </template>

      <!-- custom cell action -->
      <template #cell(action)="data">
        <div
          class="d-flex justify-content-center align-items-center"
          style="gap:5px;"
        >
          <b-button
            v-if="action_button.detail"
            @click="viewDetail(data.item._id)"
            variant="outline-info"
            size="sm"
            v-b-tooltip.hover.v-dark="'Lihat Detail'"
          >
            Detail
          </b-button>
          <b-button
            v-if="action_button.dag"
            @click="activateDag(data.item._id, data.item.status)"
            :variant="
              data.item.status == 1 ? 'outline-warning' : 'outline-success'
            "
            class="btn-icon"
            size="sm"
            v-b-tooltip.hover.v-dark="
              data.item.status == 1 ? 'Nonaktifkan DAG' : 'Aktifkan DAG'
            "
          >
            <feather-icon
              :icon="data.item.status == 1 ? 'PauseIcon' : 'PlayIcon'"
            />
          </b-button>
          <b-button
            v-if="action_button.edit"
            @click="editItems(data.item._id)"
            variant="outline-info"
            class="btn-icon"
            size="sm"
            v-b-tooltip.hover.v-dark="'Edit'"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-if="action_button.delete"
            @click="
              deleteItems(data.item._id, data.item.nama || data.item.name)
            "
            variant="outline-danger"
            class="btn-icon"
            size="sm"
            v-b-tooltip.hover.v-dark="'Hapus'"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>

      <!-- custom cell actions -->
      <template #cell(actions)="data">
        <div class="d-flex">
          <b-button
            @click="editItems(data.item._id)"
            variant="outline-info"
            class="btn-icon mr-05"
            size="sm"
            v-b-tooltip.hover.v-dark="'Edit'"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            @click="
              deleteItems(data.item._id, data.item.nama || data.item.name)
            "
            variant="outline-danger"
            class="btn-icon"
            size="sm"
            v-b-tooltip.hover.v-dark="'Hapus'"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
    <slot name="pagination">
      <pagination-component v-if="is_pagination" :data="data.page_info" />
    </slot>
  </div>
</template>

<script>
import helper from "@/helper/helper.js";
import pagination from "./pagination.vue";
import {
  BTable,
  BFormSelect,
  BPagination,
  BButton,
  VBTooltip,
  BBadge,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  name: "dataTable",
  components: {
    BTable,
    BFormSelect,
    BPagination,
    BButton,
    VBTooltip,
    BBadge,
    BFormInput,
    BFormTextarea,
    "pagination-component": pagination,
  },

  props: {
    is_row_clicked: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    action_button: {
      type: Object,
      default: function() {
        return {
          detail: true,
          dag: false,
          edit: false,
          delete: false,
        };
      },
    },
    is_pagination: {
      type: Boolean,
      default: () => true,
    },
    data: Object,
    activateDag: Function,
    viewDetail: Function,
    editItems: Function,
    deleteItems: Function,
    onProgress: Function,
    fixedData: Function,
  },
  data() {
    return {
      is_copied: false,
      text_copied: null,
    };
  },
  methods: {
    copyToClipboard(text) {
      this.text_copied = text;
      navigator.clipboard.writeText(text).then(() => {
        this.is_copied = true;
        setTimeout(() => {
          this.is_copied = false;
          this.text_copied = null;
        }, 1000);
      });
    },
    parseDate(date) {
      return helper.dateParser(date);
    },
    textCapitalize(string) {
      return helper.capitalizeText(string);
    },
    onRowClicked(item) {
      if (this.is_row_clicked) {
        this.viewDetail(item._id);
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
};
</script>
