<template>
  <div class="row mt-2">
    <!-- row information and select row -->
    <div class="col-md-5 col-sm-12">
      <div class="d-flex align-items-center float-left ml-2">
        <div>Showing {{ pagination.page }} to</div>
        <div class="ml-1 mr-1">
          <b-form-select
            v-model="pagination.items"
            :options="[5, 10, 25, 50, 100]"
            class="border-secondary"
          />
        </div>
        <div>
          of
          {{ count }} Entries
        </div>
      </div>
    </div>

    <!-- pagination -->
    <div class="col-md-7 col-sm-12">
      <div class="float-right mr-2">
        <b-pagination
          v-model="pagination.page"
          :total-rows="pagination.count"
          :per-page="pagination.items"
          size="md"
          class="pagination-info"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { BFormSelect, BPagination } from "bootstrap-vue";

export default {
  name: "pagination",
  components: {
    BFormSelect,
    BPagination,
  },
  props: {
    page: {
      type: Number,
      default: () => 1,
    },
    items: {
      type: Number,
      default: () => 10,
    },
    count: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      pagination: {
        page: this.page,
        items: this.items,
        count: this.count,
      },
    };
  },
  methods: {
    paginationChanged() {
      this.$emit("update-value", {
        page: this.pagination.page,
        items: this.pagination.items,
        count: this.pagination.count,
      });
    },
  },
  watch: {
    page(new_value) {
      this.pagination.page = new_value;
      this.paginationChanged();
    },
    items(new_value) {
      this.pagination.items = new_value;
      this.paginationChanged();
    },
    count(new_value) {
      this.pagination.count = new_value;
      this.paginationChanged();
    },
    "pagination.page"() {
      this.paginationChanged();
    },
    "pagination.items"() {
      this.paginationChanged();
    },
    "pagination.count"() {
      this.paginationChanged();
    },
  },
};
</script>
